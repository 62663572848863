const state = {
    customList:'',
    seaList:'',
    sincereResources:'',
    whiteList:'',
    inventoryResources:'',
    serviceKu:'',
    customImport:'',
    orderList:'',
    flowList:'',
    contractList:'',
    msgCenter:'',
  }
  

  
  export default {
    state,
  
  }