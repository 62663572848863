import { Message } from 'element-ui'
let tool = {
    queryMessage(rst) {
        if (rst.code == 200) {
            Message({
                message: rst.message,
                type: 'success',
            })

        } else {
            Message({
                message: rst.message,
                type: 'error',
            })

        }
    },
};

export default tool;